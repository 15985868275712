import { Component, AfterViewInit, OnInit, ViewChild, ElementRef, Directive, Output, HostListener, EventEmitter  } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { forkJoin } from 'rxjs';

// models
import { ResetPassword } from '../../shared/models/reset-password';

// services
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../../shared/services/auth.service';
import { ErrorService } from '../../shared/services/error.service';

@Component({
  selector: 'reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit, AfterViewInit {

  @ViewChild('password') password: ElementRef;

  model = new ResetPassword();
  successMessage: string;
  errorMessage: string;
  bannedPasswords: Array<string>;
  public showPassword: boolean;
  public showRepeatPassword: boolean;
  capsOn;

  constructor(
    private authenticationService: AuthService,
    private translate: TranslateService,
    private errorService: ErrorService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    forkJoin(
      this.authenticationService.getBannedPasswords()
    ).subscribe((result: Array<any>) => {
      this.bannedPasswords = result[0];
    });
  }

  ngOnInit() {
    this.model.code = this.route.snapshot.paramMap.get('code');
  }

  ngAfterViewInit() {
    this.password.nativeElement.focus();
  }

  async onSubmit() {
    this.errorMessage = null;
    if (this.model.password !== this.model.confirmPassword) {
      this.successMessage = undefined;
      this.errorMessage = await this.translate.get('USER.REPEAT_PASSWORD_VALIDATION').toPromise();
      return;
    }
    var regExp = /(.*[A-Z].*)/;
    if (!regExp.test(this.model.password)) {
      this.errorMessage = await this.translate.get('REGISTER.UPPERCASE_PASSWORD').toPromise();
    }
    var regExp = /(.*[a-z].*)/;
    if (!regExp.test(this.model.password)) {
      if(!this.errorMessage)
        this.errorMessage = await this.translate.get('REGISTER.LOWERCASE_PASSWORD').toPromise();
      else
        this.errorMessage += await this.translate.get('REGISTER.LOWERCASE_PASSWORD_REPEAT').toPromise();  
    }
    var regExp = /(.*\d.*)/;
    if (!regExp.test(this.model.password)) {
      if(!this.errorMessage)
        this.errorMessage = await this.translate.get('REGISTER.DIGIT_PASSWORD').toPromise();
      else
        this.errorMessage += await this.translate.get('REGISTER.DIGIT_PASSWORD_REPEAT').toPromise();
    }
    var regExp = /(.{8,}$)/;
    if (!regExp.test(this.model.password)) {
      if(!this.errorMessage)
        this.errorMessage = this.errorService.translations.SHORT_PASSWORD;
      else
        this.errorMessage += this.errorService.translations.SHORT_PASSWORD_REPEAT;
    }
    var regExp = /^(?!.*(.)\1{2})/s;
    if (!regExp.test(this.model.password)) {
      if(!this.errorMessage)
        this.errorMessage = await this.translate.get('REGISTER.THREE_SYMBOLS_PASSWORD').toPromise();
      else
        this.errorMessage += this.errorService.translations.THREE_SYMBOLS_PASSWORD_REPEAT;
    }
    for(let i = 0; i < this.bannedPasswords.length; i++) {
      if(this.bannedPasswords[i] === this.model.password || this.bannedPasswords[i] === this.model.confirmPassword)
        this.errorMessage = await this.translate.get('AUTHENTICATION.BANNED_PASSWORD').toPromise();
    }

    if(this.errorMessage != null) {
      return;
    }

    try {
      await this.authenticationService.resetPassword(this.model).toPromise();
      this.successMessage = await this.translate.get('LOGIN.PASSWORD_CHANGED').toPromise();
      this.errorMessage = undefined;
      // tslint:disable:no-magic-numbers
      window.setTimeout(() => this.router.navigate(['/'], { queryParamsHandling: 'merge' }), 3000);
      // tslint:enable:no-magic-numbers
    } catch (error) {
      let key = 'LOGIN.SOMETHING_WENT_WRONG';
      if (error instanceof HttpErrorResponse && 'error' in error.error) {
        key = 'LOGIN.' + error.error.error;
      }
      this.successMessage = undefined;
      this.errorMessage = await this.translate.get(key).toPromise();
    }
  }
}

@Directive({ selector: '[capsLock]' })
export class TrackCapsDirective {
  @Output('capsLock') capsLock = new EventEmitter<Boolean>();

  @HostListener('window:keydown', ['$event'])
  onKeyDown(event: KeyboardEvent): void {
    this.capsLock.emit(event.getModifierState && event.getModifierState('CapsLock'));
  }
  @HostListener('window:keyup', ['$event'])
  onKeyUp(event: KeyboardEvent): void {
    this.capsLock.emit(event.getModifierState && event.getModifierState('CapsLock'));
  }
  @HostListener('mouseenter', ['$event'])
  onMouseEnter(event: MouseEvent): void {
    this.capsLock.emit(event.getModifierState && event.getModifierState('CapsLock'));
  }
}
